import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import app from '../../assets/img/app.webp'

function CtaApp() {

  return (
    <section id="ctaApp" className="cta-app w-100 h-100 flex jcc aic">
      <Container>
        <div className="s-100"></div>
        <div className="s-100"></div>
        <div className="row">
          <div className="col-md-6">
            <div className="s-60"></div>
            <h2 className="c-theme title-small bold px-md-4 wide">Try Our App – It's Free!</h2>
            <br />
            <p className="c-white px-md-4 wide">Experience Legal Associo for Free</p>
            <p className="c-white px-md-4 wide">If you're a digital nomad or a lawyer, try the Legal Associo App today. Download and explore the future of legal document management, streamlined by AI.</p>
            <div className="s-40"></div>
            <div className="flex mb-block mx-2">
              <Link to="https://www.patreon.com/Associo" target={'_blank'} className='btn btn-danger px-5 mx-3 py-3 bold shadow-sm'>Support Us</Link>
            </div>
          </div>
          <div className="col-md-6 my-5 pb-5 my-md-0 pb-md-0">
            <img src={app} alt="Associo App" width={734} height={526} />
          </div>
        </div>
        <div className="s-100"></div>
        <div className="s-100"></div>
      </Container>
    </section>
  );
}

export default CtaApp
