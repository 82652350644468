import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
//import CountryCarousel from '../CountryCarousel'

function Hero() {
  return (
    <section id="intro">
      <div className="hero flex jcc aic">
        <Container>
          <div className="row">
            <div className="col-md-7">
              <div className="s-80"></div>
              <h1 className="intro-title c-white mt-5 pt-5 mt-md-0 pt-md-0 w-90">
                Protect Yourself and Your Business with Our Digital Nomad
                Community
              </h1>
              <div className="s-20"></div>
              <p className="intro-subtitle c-blue bold w-90">
                Join Our Telegram Bot for Insurance Resources, Risk Management &
                More
              </p>
              <div className="s-20"></div>
              <div className="w-90">
                <Link
                  className="px-5 py-3 btn btn-primary bold shadow-sm c-white text-shadow"
                  to="https://t.me/AdvogramBot"
                  target={"_blank"}
                >
                  Request Legal Help
                </Link>
              </div>
            </div>
            <div className="col-md-5">
              <div className="s-20"></div>
              {/* <CountryCarousel /> */}
              <div className="mb-5 mb-md-0"></div>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default Hero;
