import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import BankPage from "./components/BankPage/BankPage";
import LegalPage from "./components/LegalPage/LegalPage";
import InsurancePage from "./components/InsurancePage/InsurancePage";
import "./styles/custom.sass";
import LawyerPage from "./components/LawyerPage/LawyerPage";
import OnlineEntrepreneurshipPage from "./components/OnlineEntrepreneurshipPage/OnlineEntrepreneurshipPage";
import TravelPage from "./components/TravelPage/TravelPage";
import WorknLifePage from "./components/WorknLifePage/WorknLifePage";

function App() {
  const Nav = lazy(() => import("./components/Nav"));
  const Footer = lazy(() => import("./components/Footer"));
  const HomePage = lazy(() => import("./components/HomePage"));
  const AboutPage = lazy(() => import("./components/AboutPage"));
  const MissionPage = lazy(() => import("./components/MissionPage"));
  const DonatePage = lazy(() => import("./components/DonatePage"));
  const ContactPage = lazy(() => import("./components/ContactPage"));
  const BotPage = lazy(() => import("./components/BotPage"));
  const CookiePage = lazy(() => import("./components/CookiePage"));
  const PrivacyPage = lazy(() => import("./components/PrivacyPage"));
  const NotFound = lazy(() => import("./components/NotFound"));

  return (
    <BrowserRouter>
      <div className="app">
        <Suspense
          fallback={
            <div className="w-100 h-100 bg-dark c-white flex jcc aic loading">
              Loading...
            </div>
          }
        >
          <Nav />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/legal" element={<LegalPage />} />
            <Route path="/bank" element={<BankPage />} />
            <Route path="/insurance" element={<InsurancePage />} />
            <Route path="/lawyer" element={<LawyerPage />} />
            <Route
              path="/onlineentrepreneurship"
              element={<OnlineEntrepreneurshipPage />}
            />
            <Route path="/travel" element={<TravelPage />} />
            <Route path="/worknlife" element={<WorknLifePage />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/mission" element={<MissionPage />} />
            <Route path="/donate" element={<DonatePage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/legal-bot" element={<BotPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/cookie" element={<CookiePage />} />
          </Routes>
          <Footer />
        </Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;
