import { Container } from "react-bootstrap";

const Introduction = () => {
  return (
    <>
      <section style={{ padding: "60px 0 0 0" }}>
        <Container>
          <div
            style={{
              maxWidth: "500px",
              lineHeight: "1.6",
              fontWeight: 400,
              margin: "0 auto",
            }}
          >
            <p>
              Navigating legal matters as a digital nomad can be overwhelming,
              but it doesn't have to be. Our Lawyer community page is here to
              provide you with legal advice and contract review to ensure that
              you're protected while living a location-independent lifestyle.
            </p>

            <p>
              Our Telegram bot provides access to exclusive resources, including
              legal advice, contract review, and business formation guidance.
              Connect with fellow digital nomads and learn how to navigate legal
              matters and protect your interests while on the go. Join now to
              simplify your legal needs and focus on building your digital nomad
              career.
            </p>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Introduction;
