import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import about from '../../assets/img/about.webp'

function About() {
  return (
    <section id="About">
      <Container>
        <div className="s-100"></div>
        <div className="text-center">
          <h2 className='title-big c-card'>About Us</h2>
          <div className="s-20"></div>
          <h3 className='title-small c-theme bold'>Welcome to the Next Evolution in Legal Services</h3>
        </div>
        <div className="s-60"></div>
      </Container>
      <div className="w-100">
        <div className="container-fluid">

          <div className="row">
            <div className="col-md-3 al"><img src={about} alt="About Us" width={420} height={630} /></div>
            <div className="col-md-4 px-md-5 au flex jcc aic">
              <div className='mt-3 mt-md-0 mx-3 mx-md-0'>
                <p>
                  Founded in 2021, Legal Associo is at the forefront of revolutionizing legal accessibility. Our organization combines AI technology with legal expertise to offer unprecedented legal assistance to digital nomads and remote workers.
                </p>
                <p>
                  Our team consists of seasoned lawyers and tech innovators, committed to democratizing legal services. By harnessing AI, we're not just providing legal solutions; we're transforming how legal support is delivered.
                </p>
                <div className="s-40"></div>
                <Link to="/about" className='btn btn-primary px-5 py-3 bold shadow-sm'>Learn More</Link>
                <div className="mb-5 mb-md-0"></div>
              </div>
            </div>
            <div className="col-md-5 px-md-5 ar bg-blue flex jcc aic">
              <div className='my-5 my-md-0 mx-3 mx-md-0'>
                <p className='c-medium'>Legal Associo, your AI-powered legal companion, is more than a digital platform—it's a movement towards bridging the gap between dynamic, remote lifestyles and comprehensive legal support.
                </p>
                <p className='c-medium'>The journey of Legal Associo began with an insight into the unique hurdles faced by digital nomads. Traditional legal frameworks fall short in addressing the needs of a globally mobile workforce. Thus, Legal Associo was born—to tailor legal solutions for those who thrive beyond borders.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="s-100"></div>
    </section>
  );
}
export default About
