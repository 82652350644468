import { Container } from "react-bootstrap";

const Introduction = () => {
  return (
    <>
      <section style={{ padding: "60px 0 0 0" }}>
        <Container>
          <div
            style={{
              maxWidth: "500px",
              lineHeight: "1.6",
              fontWeight: 400,
              margin: "0 auto",
            }}
          >
            <p>
              Exploring the world as a digital nomad is an adventure like no
              other, but it can be overwhelming to plan and navigate. Our Travel
              community page is here to inspire and support you on your digital
              nomad journey. We've curated resources to help you plan your
              travels, find the best destinations, and connect with fellow
              digital nomads around the world.
            </p>

            <p>
              Our Telegram bot provides access to exclusive resources, including
              travel tips, destination inspiration, and local insights from
              fellow digital nomads. Connect with the community and learn how to
              explore the world as a digital nomad. Join now to unlock the
              potential of your digital nomad journey.
            </p>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Introduction;
