import { Container } from "react-bootstrap";

const Introduction = () => {
  return (
    <>
      <section style={{ padding: "60px 0 0 0" }}>
        <Container>
          <div
            style={{
              maxWidth: "500px",
              lineHeight: "1.6",
              fontWeight: 400,
              margin: "0 auto",
            }}
          >
            <p>
              Achieving work-life balance as a digital nomad can be a challenge,
              but it's essential for your success and well-being. Our Work &
              Life community page is here to help you achieve balance and thrive
              in your digital nomad career. We've curated resources to help you
              improve your productivity, manage your time, and prioritize your
              well-being.
            </p>

            <p>
              Our Telegram bot provides access to exclusive resources, including
              productivity tips, wellness advice, and mental health support.
              Connect with the community and learn how to achieve work-life
              balance as a digital nomad. Join now to unlock the potential of
              your digital nomad career and live a fulfilling life on the go.
            </p>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Introduction;
