import Contact from "./Contact";
import About from "../homePage/About";
import CtaApp from "../homePage/CtaApp";
import Services from "../homePage/Services";
import Hero from "./Hero";
import Introduction from "./Introduction";
import Reviews from "./Review";
import Solutions from "../cards/Solutions";

const WorknLifePage = () => {
  return (
    <>
      <Hero />
      <Introduction />
      <About />
      <Services />
      <Solutions />
      <CtaApp />
      <Reviews />
      <Contact />
    </>
  );
};

export default WorknLifePage;
