import { Container } from "react-bootstrap";

const Introduction = () => {
  return (
    <>
      <section style={{ padding: "60px 0 0 0" }}>
        <Container>
          <div
            style={{
              maxWidth: "500px",
              lineHeight: "1.6",
              fontWeight: 400,
              margin: "0 auto",
            }}
          >
            <p>
              Protecting yourself and your business is crucial as a digital
              nomad. Our Insurance community page is here to help you navigate
              the world of insurance and risk management. We've curated
              resources to help you protect your business, your belongings, and
              yourself while living a location-independent lifestyle.
            </p>

            <p>
              Our Telegram bot provides access to exclusive resources, including
              insurance guides, risk management tips, and safety advice. Connect
              with fellow digital nomads and learn how to protect yourself and
              your business while on the go. Join now to ensure peace of mind
              and focus on your digital nomad journey.
            </p>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Introduction;
