import React from 'react'
import { Container } from 'react-bootstrap'
import { MdGavel, MdFlight, MdMedicalServices, MdOutlineCreditCard, MdSignalWifiStatusbar3Bar, MdOutlineFitnessCenter } from "react-icons/md"

function Services() {

  const theme = '#5ABDFF'

  return (
    <section id="services">
      <div className="bg-white flex jcc aic">
        <Container>
          <div className="s-100"></div>
          <div className="text-center">
            <h2 className='title-big c-card'>Legal Services</h2>
            <div className="s-20"></div>
            <h3 className='title-small c-theme bold w-70 ma'>Global Legal Solutions at Your Fingertips</h3>
            <p className='w-60 ma mt-4 c-dark'>Get legal advice and assistance quickly and easily using the telegram bot or mobile application. From contract reviews to dispute resolutions, Legal Associo, in collaboration with global legal experts, ensures you have the support you need, wherever you are.</p>
          </div>
          <div className="s-80"></div>
          <div className="row">
            <div className="col-md-4 mb-5 px-md-5 px-4 b-left">
              <MdGavel size={32} color={theme} />
              <h3 className="title-small bold c-theme pt-3 pb-2">Legal Assistance</h3>
              <p><strong>Swift and Smart Legal Advice</strong><br />
                Get instant legal advice via our AI-powered Telegram bot or user-friendly mobile
              </p>
            </div>
            <div className="col-md-4 mb-5 px-md-5 px-4 b-left">
              <MdFlight size={32} color={theme} />
              <h3 className="title-small bold c-theme pt-3 pb-2">Visa Support</h3>
              <p><strong>Navigate International Borders with Ease</strong><br />
                Leverage AI-driven insights for up-to-date visa information and support.
              </p>
            </div>
            <div className="col-md-4 mb-5 px-md-5 px-4 b-left b-right">
              <MdMedicalServices size={32} color={theme} />
              <h3 className="title-small bold c-theme pt-3 pb-2">Travel Insurance</h3>
              <p><strong>Comprehensive Coverage for the Unexpected</strong><br />
                Choose from AI-curated travel insurance policies, ensuring your peace of mind.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-5 px-md-5 px-4 b-left">
              <MdOutlineCreditCard size={32} color={theme} />
              <h3 className="title-small bold c-theme pt-3 pb-2">Banking Solutions</h3>
              <p><strong>Global Banking Made Simple</strong><br />
                Open an international bank account with online banking features and globally accepted debit/credit cards.
              </p>
            </div>
            <div className="col-md-4 mb-5 px-md-5 px-4 b-left">
              <MdSignalWifiStatusbar3Bar size={32} color={theme} />
              <h3 className="title-small bold c-theme pt-3 pb-2">Connectivity Solutions</h3>
              <p><strong>Secure, Uninterrupted Connectivity</strong><br />
                Stay connected with our recommended VPN services, ensuring privacy and security.
              </p>
            </div>
            <div className="col-md-4 mb-5 px-md-5 px-4 b-left b-right">
              <MdOutlineFitnessCenter size={32} color={theme} />
              <h3 className="title-small bold c-theme pt-3 pb-2">Work-Life Harmony</h3>
              <p><strong>Foster Wellbeing Anywhere</strong><br />
                Discover AI-recommended wellness activities, from exercise routines to meditation sessions.
              </p>
            </div>
          </div>
          <div className="s-100"></div>
        </Container>
      </div>
    </section>
  );
}

export default Services
