import { Container } from "react-bootstrap";

const Introduction = () => {
  return (
    <>
      <section style={{ padding: "60px 0 0 0" }}>
        <Container>
          <div
            style={{
              maxWidth: "500px",
              lineHeight: "1.6",
              fontWeight: 400,
              margin: "0 auto",
            }}
          >
            <p>
              Building a successful digital business as a nomad can be
              challenging, but it doesn't have to be. Our Online
              Entrepreneurship community page is here to help you grow your
              business and achieve your goals. We've curated resources to help
              you with business strategy, marketing, and personal growth.
            </p>

            <p>
              Our Telegram bot provides access to exclusive resources, including
              business coaching, marketing tips, and personal development
              advice. Connect with fellow digital entrepreneurs and learn how to
              grow your business and achieve your goals. Join now to unlock the
              potential of your digital nomad career.
            </p>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Introduction;
