import { useMemo } from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import uuid from "react-uuid";

import img1 from "../../assets/img/reviewsOnlineEnterp/Ana Silva.png";

function Reviews() {
  const REVIEWS = useMemo(() => {
    return [
      {
        photo: img1,
        id: uuid(),
        name: "Ana Silva",
        country: "Brazil",
        position: "",
        text: "I'm so grateful for the support and resources provided through this community. The business coaching and marketing tips provided through the Telegram bot have helped me grow my digital business and achieve my goals. I highly recommend joining this community to any digital entrepreneur looking to take their business to the next level.",
      },
    ];
  }, []);

  // {
  //       photo: "",
  //       id: uuid(),
  //       name: "",
  //       country: "",
  //       position: "",
  //       text: "",
  //     },
  const settings = {
    dots: true,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,

    autoplaySpeed: 16000,
    centerMode: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section id="reviews">
      <Container>
        <div className="s-100"></div>
        <div className="s-40"></div>
        <Carousel {...settings}>
          {REVIEWS.map((e) => (
            <div className="text-center" key={e.id}>
              <blockquote>
                <img
                  src={e.photo}
                  alt={e.name}
                  width={64}
                  height={64}
                  className="ma"
                />
                <div className="s-20"></div>
                <p className="c-card w-80 ma">{e.text}</p>
                <div className="s-20"></div>
                <cite>
                  <p className="title-small bold c-theme">{e.name}</p>
                  <p className="title-mini medium c-grey">
                    {e.position} - {e.country}
                  </p>
                </cite>
              </blockquote>
            </div>
          ))}
        </Carousel>
        <div className="s-100"></div>
        <div className="s-40"></div>
      </Container>
    </section>
  );
}
export default Reviews;
