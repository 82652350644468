import { Container } from "react-bootstrap";

const Introduction = () => {
  return (
    <>
      <section style={{ padding: "60px 0 0 0" }}>
        <Container>
          <div
            style={{
              maxWidth: "500px",
              lineHeight: "1.6",
              fontWeight: 400,
              margin: "0 auto",
            }}
          >
            <p>
              As a digital nomad, legal matters can be a hassle to navigate.
              That's why our Legal community page is here to simplify your legal
              needs. We understand the challenges of living a
              location-independent lifestyle and have curated legal resources to
              help you navigate contracts, taxes, and more.
            </p>

            <p>
              Our Telegram bot provides access to exclusive resources, including
              contract templates, legal advice, and tax guides. Connect with
              fellow digital nomads and learn how to protect yourself legally
              while living a nomadic lifestyle. Join now to simplify your legal
              needs and focus on what you do best.
            </p>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Introduction;
