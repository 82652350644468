import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

function Contact() {
  return (
    <section className="bg-light">
      <Container>
        <div className="s-100"></div>
        <div className="text-center">
          <h2 className="title-big c-card">Get in touch</h2>
          <div className="s-20"></div>
          <h3 className="title-small c-theme bold">
            Join Our Telegram Bot Now
          </h3>
        </div>
        <div className="s-60"></div>
      </Container>
      <Container>
        <div className="w-60 ma wide text-center">
          <Link
            to="https://t.me/AdvogramBot"
            target={"_blank"}
            className="w-40 ma btn btn-primary py-3 px-4 mx-3 bold shadow-sm  wide"
          >
            Contact Us
          </Link>
        </div>
      </Container>
      <div className="s-100"></div>
    </section>
  );
}

export default Contact;
