import React from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RxCheck } from "react-icons/rx";
import SOLUTIONS from "../data/solutions";
import {
  FcApproval,
  FcCurrencyExchange,
  FcVoicePresentation,
  FcSportsMode,
  FcPortraitMode,
} from "react-icons/fc";

function Solutions() {
  const theme = "#5ABDFF";

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,

    autoplay: true,
    autoplaySpeed: 16000,
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="mx-md-5">
      <div className="s-100"></div>
      <div className="text-center">
        <h2 className="title-big c-card">Problems &amp; Solutions</h2>
        <div className="s-20"></div>
        <h3 className='title-small c-theme bold w-60 ma wide'>Navigating the Legal Maze of Remote Work</h3>
        <p className='w-60 ma mt-4 c-dark'>Legal Associo is your ally in overcoming the legal complexities of a digital nomad life.<br />Let our AI-driven platform guide you.</p>
      </div>
      <div className="s-60"></div>
      <Carousel {...settings}>
        {SOLUTIONS.map((el) => (
          <Container key={el.id}>
            <div className="row">
              <div className="col-md-5 px-4 h-70">
                {el.id === 1 ? (
                  <FcApproval size={48} />
                ) : el.id === 2 ? (
                  <FcCurrencyExchange size={48} />
                ) : el.id === 3 ? (
                  <FcVoicePresentation size={48} />
                ) : el.id === 4 ? (
                  <FcSportsMode size={48} />
                ) : (
                  <FcPortraitMode size={48} />
                )}
                <div className="s-10"></div>
                <h3 className="title-small c-theme bold">{el.title}</h3>
                <div className="s-10"></div>
                <p className="title-mini medium c-card">{el.desc}</p>
              </div>
              <div className="col-md-7 px-3 bg-light h-70 mb-5">
                <div className="s-60"></div>
                <div className="flex jcsa">
                  <RxCheck size={20} color={theme} />
                  <div className="mb-2 w-90 c-card title-mini">
                    {el.content[0]}
                  </div>
                </div>
                <div className="flex jcsa">
                  <RxCheck size={20} color={theme} />
                  <div className="mb-2 w-90 c-card title-mini">
                    {el.content[1]}
                  </div>
                </div>
                <div className="flex jcsa">
                  <RxCheck size={20} color={theme} />
                  <div className="mb-2 w-90 c-card title-mini">
                    {el.content[2]}
                  </div>
                </div>
                <div className="flex jcsa">
                  <RxCheck size={20} color={theme} />
                  <div className="mb-2 w-90 c-card title-mini">
                    {el.content[3]}
                  </div>
                </div>
                <div className="flex jcsa">
                  <RxCheck size={20} color={theme} />
                  <div className="mb-2 w-90 c-card title-mini">
                    {el.content[4]}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        ))}
      </Carousel>
      <div className="s-100"></div>
    </div>
  );
}
export default Solutions;
