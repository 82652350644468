const SOLUTIONS = [
  {
    id: 1,
    title: 'Legal Status and Visas',
    desc: 'Digital nomads often struggle with the legal status and visa requirements of the countries they visit. They need to be aware of the laws and regulations in each country to avoid any legal issues',
    content: [
      'Consult with an immigration lawyer before traveling to a new country to understand the visa requirements and legal status',
      'Research the visa requirements for each country you plan to visit and apply for the appropriate visa in advance',
      'Make sure you have the necessary documentation, such as a valid passport, to enter the country legally',
      'Be aware of the local laws and customs in the countries you visit and abide by them to avoid any legal issues',
      'Keep all your important documents and identification safe and secure while traveling'
    ]
  },
  {
    id: 2,
    title: 'Financial Management',
    desc: 'Digital nomads often face challenges in managing their finances while traveling. They need to ensure that they have access to their money, can make payments and manage their budget effectively',
    content: [
      'Use a digital wallet or online payment system to make payments easily and securely while traveling',
      'Open a bank account that offers online banking and a debit or credit card that can be used internationally',
      'Use budgeting apps to keep track of your expenses and plan your spending while on the road',
      'Set up automatic payments for bills and expenses to avoid missing payments while traveling',
      'Keep copies of important financial documents, such as bank statements and receipts, in a secure location in case of loss or theft'
    ]
  },
  {
    id: 3,
    title: 'Connectivity and Communication',
    desc: 'Digital nomads rely on technology and the internet to work and communicate, making reliable connectivity and communication essential for their lifestyle',
    content: [
      'Invest in a good quality laptop and mobile phone with a long battery life to stay connected while on the go',
      'Use a virtual private network (VPN) to ensure secure and private internet access while traveling',
      'Purchase a portable wifi device or international data plan to stay connected while in remote areas',
      'Use communication tools such as Skype or Zoom to communicate with clients and colleagues while traveling',
      'Research and plan ahead for places with reliable internet access and mobile coverage'
    ]
  },
  {
    id: 4,
    title: 'Health and Safety',
    desc: 'Digital nomads need to prioritize their health and safety while traveling, particularly in unfamiliar locations',
    content: [
      'Purchase comprehensive travel insurance to cover medical emergencies and other unexpected events',
      'Research the health risks and required vaccinations before traveling to a new country',
      'Take regular breaks from work to reduce the risk of burnout and prioritize self-care',
      'Use common sense and be aware of your surroundings to avoid unsafe situations',
      'Stay informed about local news and current events to avoid potential safety hazards'
    ]
  },
  {
    id: 5,
    title: 'Work-Life Balance',
    desc: 'Digital nomads often struggle to find a balance between work and personal life while traveling. They need to maintain productivity while also enjoying their travels',
    content: [
      'Set clear boundaries between work and personal time and stick to them',
      'Schedule regular breaks and downtime to explore new places and relax',
      'Use productivity tools and apps to stay organized and manage time effectively',
      'Prioritize self-care and wellness activities such as exercise, meditation or yoga',
      'Establish a routine that works for you and adjust it as needed based on your travels and work requirements'
    ]
  },
]

export default SOLUTIONS
