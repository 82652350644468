import { Container } from "react-bootstrap";

const Introduction = () => {
  return (
    <>
      <section style={{ padding: "60px 0 0 0" }}>
        <Container>
          <div
            style={{
              maxWidth: "500px",
              lineHeight: "1.6",
              fontWeight: 400,
              margin: "0 auto",
            }}
          >
            <p>
              Managing finances as a digital nomad can be a challenge, but it
              doesn't have to be. Our Bank community page is here to help you
              manage your finances and plan for your financial future. We've
              curated resources to help you navigate banking, taxes, and
              financial planning.
            </p>

            <p>
              Our Telegram bot provides access to exclusive resources, including
              banking tips, financial planning advice, and tax guides. Connect
              with fellow digital nomads and learn how to manage your finances
              while living a nomadic lifestyle. Join now to simplify your
              finances and plan for your future.
            </p>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Introduction;
